<template>
    <div class="LoginMainDiv">
        <el-row>
            <el-col :span="8">&nbsp;</el-col>
            <el-col :span="7">
                <el-form :model="loginForm" ref="from" class="vd_from">
                    <h2>FCP后台管理系统</h2>
                    <h3>登录</h3>
                    <el-form-item prop="custCode">
                        <el-input type="text" v-model="loginForm.adminCode" maxlength="16" placeholder="请输入管理员账号"
                             size="medium" class="vd_input" clearable show-word-limit>
                            <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
                        </el-input>
                    </el-form-item>

                    <el-form-item prop="custPass">
                        <el-input @keyup.enter.native="chooseLogin" type="请输入密码" v-model="loginForm.adminPass" minlength="6" maxlength="16"
                            placeholder="Password" class="vd_input"  show-password clearable>
                            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                        </el-input>
                    </el-form-item>

                    <el-form-item class="vd_login_button">
                        <el-button type="primary" @click="chooseLogin" round>
                            <span class="signInText">登 录</span>
                        </el-button>
                    </el-form-item>

                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
y
<script>
import { post } from "@/api/request";
import  {adminAPI}  from "@/api/modules/admin";
export default {
    name: 'login',
    data() {
        return {
            loginForm: {
                adminCode: "",
                adminPass: ""
            }
        }
    },
    methods: {
        chooseLogin() {
            console.log('131313');
            let loginForm = {}
            loginForm = JSON.parse(JSON.stringify(this.loginForm))
            if (!loginForm.adminCode && !loginForm.adminPass) {
                this.$message.warning("请输入用户名和密码！")
                return;
            }
            post(adminAPI.adminLogin, loginForm)
                .then((res) => {
                    // 登录成功
                    if (res.data.code === 0) {
                        localStorage.setItem("loginInfo",JSON.stringify(res.data.data));
                        this.$message({ message: '欢迎进入FCP后台管理系统', type: 'success' });
                        this.$router.push('/admin_home');
                    } else {
                        //登陆不成功
                        let mg = res.data.msg;
                        let tp = "error";
                        this.$message({ message: mg, type: tp });
                    }
                })
                .catch(() => { });
        }
    }
}
</script>

<style>
/* 顶级div，设置背景图片 */
.LoginMainDiv {
    background: ghostwhite;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
}
/* 图片素材 */
.vd_login_image {
    margin-top: 30%;
}
/* 登录表单 */
.vd_from {
    padding: 30px;
    margin-top: 35%;
}
/*  登录表单输入框 */
.vd_input{
    margin-top: 20px;
    border: 1px solid black;
    border-radius: 12px;
    padding: 2px 2px 2px 10px;

}
/* 登录按钮 */
.vd_login_button{
    margin-top: 40px;
}
/* 登录按钮字体 */
.signInText{
    padding: 0 20px 0 20px;
}
/* 改变el-input自带样式 */
.LoginMainDiv .el-input__inner{
   border-top: none !important;
   border-left: none !important;
   border-right: none !important;
   border-bottom: none !important;
   border-radius: 12px;
   background: none !important;
   /* font-size: 16px !important; */
 }
 /* 输入框前的图标 */
 i{
    color: black;
 }
 h2{
    padding-bottom: 20px;
 }
</style> 